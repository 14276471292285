import React from 'react';

function Image({
  className,
  image,
  lazyLoad = true,
  size,
  video,
}) {
  const classes = `${className}`
  const aspectRatio = image.dimensions.height / image.dimensions.width * 100 + '%';
// const aspectRatio = '100%'
//   const extension = image.url?.split('.').pop().split('?')[0]
  const bitmapImagePath = getImageUrl(image.url, size ? size : 'medium', 75, 'bitmap')
  const sizes = getSizes(size ? size : 'medium')

  return (
    <>
      {/* {(extension !== 'svg') && ( */}
        <div className={`c-image ${classes}`} style={{'paddingTop': aspectRatio}}>
          {/* <img alt='' aria-hidden='true' className={styles.imagePlaceholder} src={image.base64} /> */}
          <picture>
            <img alt={image.alt ? image.alt : ''} className={'imageSrc'} decoding='async' loading={lazyLoad ? 'lazy' : 'eager'} sizes={`(min-width: ${sizes}) ${sizes}, 100vw`} src={`${image.src}&w=10&q=65`} srcSet={bitmapImagePath} />
          </picture>
          {video && (
            <video autoPlay={true} className="c-image__video" loop={true} muted={true} playsInline={true} src={video} />
          )}
        </div>
      {/* )} */}
      {/* {(extension === 'svg') && (
        <img alt={image.alt ? image.alt : ''} className={classes} loading='lazy' src={image.url} />
      )} */}
    </>
  )
}

function getImageUrl(imageUrl, size, quality, type) {
  const imagePath = (type === 'webp') ? `${imageUrl}&fm=${type}&lossless=false&q=${quality}`: `${imageUrl}&q=${quality}`;

  switch(size) {
    case 'xxlarge':
      return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=1920 1920w, ${imagePath}&w=2560 2560w`;
    case 'xlarge':
      return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=1920 1920w`;
    case 'large':
      return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w`;
    case 'medium':
      return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w`;
    case 'small':
      return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w`;
    case 'xsmall':
      return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w`;
    case 'xxsmall':
      return `${imagePath}&w=375 375w`;
    default:
      let customImage = `${imagePath}&w=${size} ${size}w`;

      if (size > 375) {
        customImage = `${imagePath}&w=375 375w, ${imagePath}&w=${size} ${size}w`
      }

      if (size > 480) {
        customImage = `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=${size} ${size}w`
      }

      if (size > 768) {
        customImage = `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=${size} ${size}w`
      }

      if (size > 960) {
        customImage = `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=${size} ${size}w`
      }

      if (size > 1280) {
        customImage = `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=${size} ${size}w`
      }

      return customImage;
  }
}

function getSizes(size) {
  switch(size) {
    case 'xxlarge':
      return '2560px';
    case 'xlarge':
      return '1920px';
    case 'large':
      return '1280px';
    case 'medium':
      return '960px';
    case 'small':
      return '768px';
    case 'xsmall':
      return '480px';
    case 'xxsmall':
      return '375px';
    default:
      return `${size}px`;
  }
}

export { Image }
