import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';

function LinkOpacity({
    linkClass, text, url,
}) {
    return (
        <>
        <TransitionLink
            className={linkClass}
            to={url}
            exit={{
                trigger: ({
                    node, e,
                }) => {
                    const link = e.target;
                    const nav = node.querySelector('.c-nav');
                    const page = node.querySelector('.b-page');
                    const pageOffset = -(Math.round(window.scrollY - nav.offsetHeight));

                    // Set the current page to fixed and set top position to
                    // match scroll position, also hide the clicked link
                    page.classList.add('is-exiting-opacity');
                    page.style.top = `${pageOffset}px`;
                },
                length: 0.45,
            }}
            entry={{
                trigger: ({
                    node, e,
                }) => {
                    const html = document.documentElement;
                    const link = e.target;
                    const page = node.querySelector('.b-page');
                    const oldPage = document.querySelector('.b-page.is-exiting-opacity');

                    // Hide the clicked link
                    link.style.opacity = 0;

                    // Being the animation from work item to masthead
                    html.style.overflow = 'hidden';
                    page.classList.add('is-entering-opacity');

                    page.addEventListener('animationend', () => {
                        html.style.overflow = '';
                        page.classList.remove('is-entering-opacity');
                    });
                },
                delay: 0.45,
                length: 0.45,
            }}>{text}</TransitionLink>
        </>
    );
}

LinkOpacity.defaultProps = {
    linkClass: '',
};

LinkOpacity.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default LinkOpacity;
