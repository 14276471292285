import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import LinkOpacity from '../components/link-opacity';

function globalData() {
    const data = useStaticQuery(graphql`
        query globalData {
            allPrismicGlobal(
                sort: {
                    fields: [lang],
                    order: ASC
                }
            ) {
                edges {
                    node {
                        lang
                        data {
                            nav {
                                name
                                url {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function Nav({
    lang,
}) {
    const data = globalData();
    let nav;

    data.allPrismicGlobal.edges.map(({ node }) => (
        node.lang === lang && (
            nav = node.data
        )
    ));

    return (
        <>
            <nav className="c-nav">
                <div className="c-nav__wrap b-wrap">
                    <LinkOpacity
                        linkClass="c-nav__logo-mobile"
                        text="DS"
                        url="/"
                    />

                    <LinkOpacity
                        linkClass="c-nav__logo-desktop"
                        text="David Sewell"
                        url="/"
                    />

                    <div className="c-nav__link-container">
                        {nav.nav && (
                            <ul className="c-nav__list">
                                {nav.nav.map(({ name, url }, index) => {
                                    let urlPrefix;
                                    if (lang === 'en-gb') {
                                        urlPrefix = '';
                                    } else if (lang !== 'en-gb') {
                                        urlPrefix = `/${lang.substring(0, lang.indexOf('-'))}`;
                                    }
                                    return (
                                        <li className="c-nav__item" key={index}>
                                            <LinkOpacity
                                                linkClass="c-nav__link"
                                                text={name}
                                                url={`${urlPrefix}${(url.url === '/home') ? '/' : url.url}`}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        )}

                        {data.allPrismicGlobal.edges[0].node && (
                            <ul className="c-nav__language-list">
                                {data.allPrismicGlobal.edges.map(({ node }, index) => (
                                    <li className="c-nav__language-item" key={index}>
                                        <LinkOpacity
                                            linkClass={`${(node.lang === lang) ? 'c-nav__language-link is-active' : 'c-nav__language-link'}`}
                                            text={node.lang.substring(0, node.lang.indexOf('-'))}
                                            url={(node.lang === 'en-gb') ? '/' : `/${node.lang.substring(0, node.lang.indexOf('-'))}/`}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}

Nav.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Nav;
